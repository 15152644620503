import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { EMPTY, Observable, TimeoutError, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '@features/auth/core/domain/login/auth-version-2.service';
import { AppLoadingOverlayService } from '@shared/components/app-loading-overlay/app-loading-overlay.service';
import _ from 'lodash';
import { NgbModal } from '@shared/components/lib-ng/lib-ng-bootstrap/modal/modal';

//DOC: https://angular.io/guide/http-intercept-requests-and-responses
@Injectable()
export class AppHttpResponseInterceptor implements HttpInterceptor {
  constructor(
    private readonly authService: AuthService,
    private readonly loadingOverlay: AppLoadingOverlayService,
    private readonly modalService: NgbModal,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    /**
     * PIPE: [---> handleGlobalError ---> mappingHttpErrorResponseToErrorBase ----> ]
     */
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse | TimeoutError) => {
        if (error instanceof TimeoutError) {
          this.loadingOverlay.setState({ isLoading: false });
          return EMPTY;
        }

        this.handleHttpError(error);
        return throwError(() => error);
      })
    );
  }
  private handleHttpError = (error: HttpErrorResponse) => {
    switch (error.status) {
      case 0:
        return EMPTY;
      case HttpStatusCode.RequestTimeout:
        return EMPTY;
      case HttpStatusCode.InternalServerError:
        return EMPTY;
      default:
        break;
    }
    return error;
  };
}
