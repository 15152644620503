import { AppComponent } from './app/app.component';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { importProvidersFrom, isDevMode } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { ToastrModule } from '@shared/components/lib-ngx/ngx-toastr/toastr/toastr.module';
import { environment } from '@config/environments/environment';
import { TranslocoRootModule } from '@shared/components/zaa-transloco-root/transloco-root.module';
import { AppRoutingModule } from './app/app.routing.module';
import { TuiRootModule } from '@taiga-ui/core';
import { CoreAuthModule } from '@features/auth/public-api';
import { API_VERSIONs } from '@config/api.config';
import { GlobalPolcies } from '@config/policy/policy.config';
import { AppHttpResponseInterceptor } from '@core/interceptors/app-response.interceptor';
import { AuthVersion } from '@features/auth/const';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { AppDeviceKeyInterceptor } from '@features/tai-chinh-thu-hoc-phi/interceptor/device-id.interceptor';

const APP_BOOTSTRAP_CONFIG = {
  providers: [
    importProvidersFrom([
      // Core lib modules
      BrowserModule,
      HttpClientModule,
      AppRoutingModule,
      KeyboardShortcutsModule.forRoot(),
      CoreAuthModule.forRoot({
        application: {
          authDomain: environment.domain.authDomain,
          appKey: environment.authen.appKey,
          authVersion: AuthVersion.VERSION_2,
          apiVersion: API_VERSIONs.VERSION_2_0_0,
          useCustomLoginPageURL: environment.authen.useCustomLoginPageURL,
          branch: 'HUTECH', // ROYAL | UEF
          tokenType: 'JWT',
          apiVersionHeader: 'X-Api-Version',
        },
        globalPolicies: GlobalPolcies,
        defaultAuth: {
          reCaptcha: {
            enable: false,
            secretKey: environment.authen.reCapchaSecretKey,
          },
          typeAccount: {
            useDefault: true, // Default NHAN_VIEN - SINH_VIEN
            enable: false,
          },
          register: {
            enable: false,
          },
          forgotPassword: {
            enable: false,
          },
        },
        socialNetworkAuth: {
          facebook: {
            enable: false,
          },
          google: {
            enable: false,
          },
        },
      }),
      // Global modules
      TranslocoRootModule,
      ToastrModule.forRoot({ timeOut: 3000 }),
      TuiRootModule,
    ]),
    provideAnimations(),
    // Interceptor http provider
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppDeviceKeyInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpResponseInterceptor,
      multi: true,
    },
   
  ],
};

bootstrapApplication(AppComponent, APP_BOOTSTRAP_CONFIG)
  .then(() => {
    if (isDevMode()) {
      console.group(`Bootstrap Angular application success`);
    }
  })
  .catch((err) => {
    console.error(`Error when bootstrap application: ${err.message}`);
  });
