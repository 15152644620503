import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DeviceIdHelper } from '../util/device-id.helper';

@Injectable()
export class AppDeviceKeyInterceptor implements HttpInterceptor {
  deviceIdHelper = inject(DeviceIdHelper);
  DEVICE_ID_KEY = 'x-client-device-id';

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const deviceId = this.deviceIdHelper.getDeviceId();

    if (!deviceId) {
      return next.handle(request);
    }

    const cloneReq = request.clone({
      headers: request.headers.set(this.DEVICE_ID_KEY, deviceId),
    });

    return next.handle(cloneReq);
  }
}
